<template>
  <v-container grid-list-s text-xs-center class="pt-16">
    <v-layout row wrap>
      <v-col md="6" offset-md="3" align="center">
        <h4 class="display-2 font-weight-bold " color="green">
          {{ $t('forgotPassword') }}
        </h4>
        <br />
        <v-form
          v-if="!resetSuccessful"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="resetUserPassword"
        >
          <v-text-field
            v-model="email"
            clearable
            type="email"
            placeholder="email@example.com"
            :label="$t('email')"
            prepend-icon="mdi-email"
            color="primary"
            required
            :rules="[emailRules]"
            @input="emailError = ''"
          >
          </v-text-field>

          <br />
          <v-btn :disabled="!valid" color="primary" large type="submit">
            {{ $t('resetPassword') }}
          </v-btn>
        </v-form>
        <div v-else>{{ resetMessage }}</div>
      </v-col>
    </v-layout>
  </v-container>
</template>
<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  data: () => ({
    email: '',
    emailError: '',
    resetSuccessful: false,
    resetMessage: '',
    valid: true
  }),
  methods: {
    ...mapActions(['resetPassword']),
    emailRules(value) {
      if (!value) return this.$t('requiredError')
      else if (this.emailError) return this.emailError
      else return true
    },

    async resetUserPassword() {
      const email = this.email
      await this.resetPassword(email)
        .then(res => {
          if (res.status !== 200) {
            this.resetSuccessful = false
            this.emailError = res.data.email && res.data.email[0]
            console.log(this.emailError)
            this.$refs.form.validate()
          } else {
            this.resetSuccessful = true
            this.resetMessage = res.data
          }
        })
        .catch(({ err }) => {
          console.log(err)
        })
    }
  }
})
</script>
