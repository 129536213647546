<template>
  <v-container grid-list-s text-xs-center class="pt-16">
    <v-alert
      v-if="resetSuccessful"
      :value="resetSuccessful"
      type="success"
      dark
      border="top"
      icon="mdi-home"
      transition="scale-transition"
    >
      {{ resetMessage }}
    </v-alert>
    <v-layout row wrap>
      <v-col md="6" offset-md="3" align="center">
        <h4 class="display-2 font-weight-bold " color="green">
          {{ $t('resetPassword') }}
        </h4>
        <br />
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          name="reset_password"
          @submit.prevent=""
        >
          <v-text-field
            v-model="newPassword"
            outlined
            type="password"
            :label="$t('newPassword')"
            color="primary"
            maxlength="255"
            required
            :rules="[newPasswordRules]"
          />

          <v-text-field
            v-model="confirmNewPassword"
            outlined
            type="password"
            :label="$t('confirmNewPassword')"
            color="primary"
            :rules="[passwordRules]"
            required
          />
          <v-btn
            depressed
            :disabled="!valid"
            color="primary"
            large
            type="submit"
            class="float-right"
            @click="confirmResetUserPassword"
          >
            {{ $t('changePassword') }}
          </v-btn>
        </v-form>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ForgotPasswordConfirm',
  data: () => ({
    uid: '',
    token: '',
    newPassword: '',
    confirmNewPassword: '',
    valid: true,
    resetSuccessful: false,
    resetMessage: ''
  }),
  created() {
    this.uid = this.$route.query.uid
    this.token = this.$route.query.token
  },
  methods: {
    ...mapActions(['confirmResetPassword']),
    newPasswordRules(value) {
      if (!value) {
        return this.$t('requiredError')
      } else {
        if (this.newPasswordError) {
          return this.newPasswordError
        } else return true
      }
    },
    passwordRules(value) {
      if (!value) {
        return this.$t('requiredError')
      } else {
        if (value !== this.newPassword) {
          return this.$t('passwordNotMatching')
        } else return true
      }
    },
    async confirmResetUserPassword() {
      if (this.$refs.form.validate()) {
        await this.confirmResetPassword({
          uid: this.uid,
          token: this.token,
          new_password: this.newPassword
        })
          .then(res => {
            if (res.status !== 200) {
              this.newPasswordError =
                res.data.new_password && res.data.new_password[0]
              this.$refs.form.validate()
            }
            this.resetSuccessful = true
            this.resetMessage = res.data
          })
          .catch(err => {
            console.log(err)
          })
      }
    }
  }
}
</script>

<style scoped></style>
